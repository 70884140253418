
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ScreenWrapper',
  props: {
    next: Function,
    hideArrow: Boolean,
    screen: String,
    className: String,
    wrapperClasses: String
  },

  data() {
    const root = this.$root as any;

    return {
      locales: {
        en: 'English',
        de: 'Deutsch'
      },
      scrolledToBottom: null,
      appEl: null,
      interval: null,
      // screensOptions: Object.values(root.screens || []).map(({key}) => ({
      //   label: `screen.${key}`,
      //   code: `${key}`
      // })).map((option, index) => ({code: option.code, label: (index + 1) + '. ' + this.$t(option.label)})) || [],
    }
  },

  computed: {
    screenIndex() {
      return this.screensOptions.findIndex(screen => screen.code === this.selectedPage) + 1
    },
    audioOn() {
      return this.$root.audioOn;
    },
    selectedPage() {
      return this.$root?.currentScreen ?? '';
    },
    isSelectedPage() {
      return this.selectedPage === this.screen;
    },
    locale() {
      console.log(this.$root.$i18n)
      return this.locales.find(locale => locale.val === this.$root.$i18n.locale)
    },
    localeCountries() {
      return {en: 'gb', de: 'de'}
    }
  },

  mounted() {
    this.appEl = this.$root.$el.parentNode;

    this.interval = setInterval(() => {
      this.updateScrollToBottom()
    }, 1000)

    this.appEl.addEventListener('scroll', this.updateScrollToBottom)
  },

  beforeUnmount() {
    clearInterval(this.interval)
    this.appEl?.removeEventListener('scroll', this.updateScrollToBottom)
    this.$root.stopAudio();
  },

  methods: {
    goTo(screen) {
      this.$root.goTo(screen.code)
    },
    arrowClick() {
      this.$props?.next();
      console.log(this.appEl.scrollY, this.appEl.outerHeight)
      this.appEl.scrollBy({
        top: this.appEl.outerHeight
      })
    },
    updateScrollToBottom() {
      if (!this.appEl) return;

      if (this.appEl.scrollHeight <= window.innerHeight) {
        this.scrolledToBottom = true;
        clearInterval(this.interval);

      } else if (window.innerHeight + this.appEl.scrollTop + 50 >= this.appEl.scrollHeight) {
        this.scrolledToBottom = true;
      } else {
        this.scrolledToBottom = false;
      }
    },
    selectLocale(locale) {
      console.log('Switching locale to: ', locale);

      const urlQuery = new URLSearchParams(window.location.search);
      urlQuery.set('locale', locale);

      window.location.search = urlQuery.toString();
      // this.$root.$i18n.locale = locale;
    }
  }
});
