import {ref} from "vue";
import axios from "axios";

const state = JSON.parse(localStorage.getItem('state') || '{}');
let project = localStorage.getItem('project') || 'default';

const nickname = ref(state.nickname || '')
const answers = ref(state.answers || {})
const hash = ref(state.hash || {})
const locale = ref(state.locale || 'en')

const saveState = () => {
    localStorage.setItem('state', JSON.stringify({
        nickname: nickname.value,
        answers: answers.value,
        hash: hash.value,
        locale: locale.value
    }))
}

function saveToApi(id, answer, texts: Record<string, string> = {}) {
    let stringAnswer = answer;

    if (typeof answer === 'object') {
        stringAnswer = JSON.stringify(answer);
    } else if (Array.isArray(answer)) {
        stringAnswer = answer.join(',');
    }

    axios.post(process.env.VUE_APP_SUBMIT_API + '/pitool/response', {
        hash: hash.value,
        question: `${id}`,
        answer: `${stringAnswer}`,
        source: window.location.hostname,
        locale: locale.value || 'en',
        texts: texts
    }).then((response) => {
        console.log(response);
    });
}

const setAnswer = (id: string, answer: unknown, texts: Record<string, string>) => {
    answers.value = {...answers.value, [id]: answer}
    saveState();

    saveToApi(id, answer, texts);
}

const setNickname = (name) => {
    console.log('setNickname', name)
    nickname.value = name;
    saveState();
}


function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const setHash = () => {
    const hashValue = makeid(10);
    console.log('setHash', hashValue)
    hash.value = hashValue;
    saveState();
}

const resetState = () => {
    nickname.value = '';
    answers.value = {};
    hash.value = '';
    saveState();
    setHash();
}

export const useState = () => {
    return {
        nickname: nickname,
        answers: answers,
        hash: hash,
        setHash: setHash,
        setAnswer: setAnswer,
        setNickname: setNickname,
        resetState: resetState
    }
}

export const setProject = (projectSlug: string, title?: string) => {
    project = projectSlug.toLowerCase();
    localStorage.setItem('project', project);

    window.document.title = title || 'PI eLab – ' + projectSlug;

    return project;
}

export const getProject = () => {
    return project;
}
