
import {defineComponent} from 'vue';
import {useState} from "@/hooks/state";
import ActionButton from "@/components/elements/ActionButton.vue";

const state = useState();

export default defineComponent({
  name: 'BackNextButtons',
  components: {ActionButton},
  emits: ['click'],
  props: {
    goTo: Function,
    nextScreen: String,
    colorClass: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: 'action-button'
    }
  },

  data() {
    return {
      state
    }
  },

  methods: {
    click(button: number) {
      this.$emit('click', button);
    }
  }
});
