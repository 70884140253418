import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionWrapper = _resolveComponent("TransitionWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container-2xl mx-auto relative h-full align-middle", _ctx.deviceClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.screenConfigs, (config, key) => {
      return (_openBlock(), _createBlock(_component_TransitionWrapper, {
        key: key,
        index: key,
        "current-screen": _ctx.currentScreen,
        back: _ctx.isGoingBack,
        instant: config.instant
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(config.component), _mergeProps({goTo: _ctx.goTo}, {
              screen: key,
              config: config
            }), null, 16, ["screen", "config"]))
          ], 1024))
        ]),
        _: 2
      }, 1032, ["index", "current-screen", "back", "instant"]))
    }), 128))
  ], 2))
}