
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreensTypes} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper,
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreensTypes,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      list: [],
      selectedValue: 0,
    }
  },

  mounted() {
    const dataList = [];
    for (let i = this.config.answerMin; i <= this.config.answerMax; i += this.config.answerStep) {
      dataList.push({
        id: i,
        name: `${i} ${this.$t(this.config.answerUnit, {name: state.nickname.value})}`
      })
    }

    this.list = dataList;
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    }, back() {
      this.goTo(-1)
    },
  }
});
