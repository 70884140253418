import {Howl} from "howler";

export const audioPlayerMixin = {
    data() {
        return {
            sound: null as Howl | null,
            audioOn: false,
            playPromise: Promise,
            url: null,
        }
    },
    computed: {
        loadingSound() {
            return this.sound && this.sound.state() === 'loading'
        }
    },

    methods: {
        setAudioOn(on) {
            this.audioOn = on;
        },

        playAudio(url: string) {
            this.setAudioOn(true)

            this.setupAndPlayIfOn(url)
        },

        async stopAudio() {
            if (this.sound) {
                await this.playPromise;
                this.sound.pause();
            }
        },

        async setupAndPlayIfOn(url: string) {
            if (this.sound) {
                await this.playPromise;
                this.sound.stop();
            }

            this.url = url;
            this.sound = new Howl({
                src: [url],
                preload: this.audioOn,
                autoplay: this.audioOn,
                volume: 1,
            });

            if (this.audioOn) {
                this.playPromise = new Promise((okCb) => {
                    this.sound.once("play", okCb)
                })
            }
        },

        async togglePlay(start) {
            console.log(this.sound, start)
            if (!this.sound) return;

            await this.playPromise

            this.setAudioOn(start);

            if (!start) {
                this.sound.pause();
            } else {
                if (this.sound.state() === 'unloaded') {
                    this.sound.load();
                }
                this.playPromise = Promise.resolve(this.sound.play());
            }
        },
    },

}
