
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'TransitionWrapper',
  props: {
    index: String,
    currentScreen: String,
    back: Boolean,
    instant: Boolean,
  },
  data() {
    return {
      display: false
    }
  },

  mounted() {
    if (this.isActive) {
      this.display = true;
    }
  },
  computed: {
    animationName() {
      if (this.instant) {
        return ''
      }

      return this.back ? 'screen-back' : 'screen';
    },

    isActive() {
      return this.$props.index === this.$root.currentScreen;
    },
  },
  watch: {
    isActive(before, after) {
      console.log('isActive', before, after);
      if (!before && after) {
        this.display = true;
        setTimeout(() => {
          window.scrollTo({
            top: 0
          })
        }, 2100);
      }
    },
  },
  methods: {
    updateVisibility() {
      if (this.isActive) {
        this.display = true;
        // this.$refs.TransitionWrapper.scrollTo({
        //   top: 0
        // })
      } else {
        if (this.instant) {
          this.display = false;
        }

        setTimeout(() => {
          if (!this.isActive) {
            // Check again
            this.display = false;
          }
        }, 2100)
      }
    }
  }
});
