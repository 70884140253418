
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigOptions} from "@/Screens.types";
import draggable from 'vuedraggable';
import ActionButton from "@/components/elements/ActionButton.vue";

const state = useState();
export default defineComponent({
  components: {
    ActionButton,
    ScreenWrapper,
    draggable
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigOptions,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      list: [],
    }
  },

  mounted() {
    const existingList = state.answers.value?.[this.config.answerKey] || [];
    const list = Object.entries(this.config.options).reduce((acc, [key, value]) => {
      acc.push({id: key, name: value});
      return acc;
    }, []);

    if (existingList.length === 0) {
      // set random order
      list.sort(() => Math.random() - 0.5);
    }

    this.list = list.sort((a, b) => {
      const aIndex = existingList.indexOf(a.id);
      const bIndex = existingList.indexOf(b.id);
      if (aIndex === -1) {
        return 1;
      }
      if (bIndex === -1) {
        return -1;
      }
      return aIndex - bIndex;
    });
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },

    answer() {
      const answer = this.list.map((item) => item.id);
      const texts = this.list.reduce((acc, item) => {
        acc[item.id] = this.$t(item.name);
        return acc;
      }, {});
      state.setAnswer(this.config.answerKey, answer, {
        [this.config.answerKey]: this.$t(this.config.question),
        ...texts
      });
      // this.next();
    }
  }
});
